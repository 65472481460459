import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button } from "@mui/material";

export const WebinarModal = ({ open, onClose, selectedWebinar, text }) => {
  const { link, description, starts_at } = selectedWebinar || {};
  const dateTime = new Date(starts_at);

  // const weekDay = dateTime.toLocaleString("de-DE", { weekday: "long" });

  const date = text.language === "de" ?
      `${dateTime.toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })}`:
      `${dateTime.toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })}`

  const time = text.language === "de" ?
      `um ${dateTime.toLocaleString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      })} Uhr` :
      `at ${dateTime.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      })} `

    const weekDay = text.language === "de" ?
        `Am ${dateTime.toLocaleString("de-DE", { weekday: "long" })}` :
        dateTime.toLocaleString("en-US", { weekday: "long" })

    return (
    <Dialog onClose={onClose} open={open} maxWidth={"sm"} style={{minWidth: '374px'}} fullWidth={true}>
      <div className="webinar-modal">
        <div className="webinar-modal__header">
          <h3 className="webinar-modal__title">{text.webinar}</h3>
          <IconButton className="webinar-modal__close-button" onClick={onClose}>
            <CloseRoundedIcon fontSize={'large'} />
          </IconButton>
        </div>
        <div className="webinar-modal__body">
          <p className="webinar-modal__description">{description}</p>
          <div className="webinar-modal__date">{`${weekDay}, ${date} ${time} `}</div>
        </div>
        <a className="webinar-modal__button" href={link} target='_blank' rel="noreferrer">{text.signUp}</a>
      </div>
    </Dialog>
  );
};
