import loader from "../assets/loader.svg";


export const ResultPreview = ({ isLoading, error, text }) => {
	return (
		<div className="result-preview">
			{!isLoading ? (
				<>
					{!error ? (
						<>
							<h3 className="result-preview__title">This is where the magic will happen!</h3>
							<p className="result-preview__text">
								{text.enterKeyword}
							</p>
						</>
					) : (
						<h3 className="result-preview__error">{error}</h3>
					)}
				</>
			) : (
				<>
					<img src={loader} alt="loader" className="result-preview__loader" />
					<p className="result-preview__text">{text.waiting}</p>
				</>
			)}
		</div>
	);
};
