export const RocketLogo = () => {
  return (
    <div className="rocket-logo">
      <svg
        width="1368"
        height="130"
        viewBox="0 0 1368 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="rocket-logo__divider"
      >
        <path
          opacity="0.5"
          d="M-5.24536e-06 29.9999C-5.24536e-06 29.9999 355.5 7.82393e-06 684 0.000119594C1012.5 0.000231365 1368 30.0003 1368 30.0003L1368 130L-2.19531e-05 130L-5.24536e-06 29.9999Z"
          fill="url(#paint0_linear_204:474)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_204:474"
            x1="684"
            y1="-19.9998"
            x2="684"
            y2="130"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AEB9D1" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="253"
        height="150"
        viewBox="0 0 253 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="rocket-logo__image"
      >
        <path
          d="M136.304 133.509H135.385C134.715 133.569 134.039 133.489 133.402 133.273C132.764 133.058 132.179 132.711 131.683 132.256C131.187 131.801 130.791 131.248 130.522 130.631C130.252 130.014 130.114 129.348 130.117 128.675V120.749H119.677V128.724C119.68 129.397 119.542 130.063 119.272 130.679C119.002 131.296 118.607 131.85 118.111 132.305C117.615 132.76 117.03 133.106 116.392 133.322C115.754 133.537 115.079 133.617 114.409 133.557H113.49C110.473 133.557 107.579 134.752 105.441 136.881C103.304 139.009 102.096 141.898 102.083 144.915H147.759C147.759 143.413 147.462 141.926 146.886 140.539C146.309 139.152 145.465 137.892 144.401 136.832C143.336 135.773 142.073 134.933 140.684 134.363C139.294 133.793 137.806 133.502 136.304 133.509Z"
          fill="url(#paint0_linear_204:475)"
        />
        <path
          d="M106.723 97.9839L93.2382 105.282C90.8766 106.527 88.7985 108.248 87.1348 110.336C85.4711 112.424 84.2579 114.834 83.5715 117.414L83.1365 119.057C82.9246 119.849 82.9601 120.686 83.2382 121.457C83.5163 122.227 84.0237 122.894 84.6921 123.368C85.3606 123.842 86.1581 124.099 86.9774 124.106C87.7966 124.114 88.5984 123.869 89.2749 123.407L93.5282 120.556C95.2053 119.447 97.1727 118.858 99.1832 118.864H117.163"
          fill="url(#paint1_linear_204:475)"
        />
        <path
          d="M143.07 97.9839L156.555 105.282C158.917 106.527 160.995 108.248 162.659 110.336C164.322 112.424 165.536 114.834 166.222 117.414L166.657 119.057C166.869 119.849 166.833 120.686 166.555 121.457C166.277 122.227 165.77 122.894 165.101 123.368C164.433 123.842 163.635 124.099 162.816 124.106C161.997 124.114 161.195 123.869 160.519 123.407L156.072 120.749C154.395 119.64 152.427 119.051 150.417 119.057H132.63"
          fill="url(#paint2_linear_204:475)"
        />
        <path
          d="M127.41 0.785968C126.708 0.275157 125.861 0 124.993 0C124.125 0 123.279 0.275157 122.576 0.785968C105.563 13.981 94.0597 38.1477 94.0597 65.8428C93.6015 86.4174 100.452 106.488 113.393 122.49C113.78 122.932 114.255 123.289 114.788 123.539C115.32 123.788 115.899 123.925 116.486 123.94H124.655H132.823C133.411 123.925 133.989 123.788 134.522 123.539C135.054 123.289 135.529 122.932 135.916 122.49C148.858 106.488 155.708 86.4174 155.25 65.8428C155.733 38.1477 144.23 13.981 127.41 0.785968Z"
          fill="url(#paint3_linear_204:475)"
        />
        <path
          d="M137.947 53.71H111.847V120.217C112.427 120.942 113.007 121.763 113.587 122.488C113.974 122.931 114.449 123.288 114.982 123.538C115.514 123.787 116.092 123.924 116.68 123.938H124.849H133.017C133.605 123.924 134.183 123.787 134.716 123.538C135.248 123.288 135.723 122.931 136.11 122.488C136.739 121.763 137.27 120.99 137.85 120.217L137.947 53.71Z"
          fill="url(#paint4_linear_204:475)"
        />
        <path
          d="M124.897 64.6826C132.104 64.6826 137.947 58.8399 137.947 51.6326C137.947 44.4252 132.104 38.5825 124.897 38.5825C117.69 38.5825 111.847 44.4252 111.847 51.6326C111.847 58.8399 117.69 64.6826 124.897 64.6826Z"
          fill="#DCE0E9"
        />
        <path
          d="M110.349 30.2213C112.11 30.2213 113.539 28.7931 113.539 27.0313C113.539 25.2695 112.11 23.8413 110.349 23.8413C108.587 23.8413 107.159 25.2695 107.159 27.0313C107.159 28.7931 108.587 30.2213 110.349 30.2213Z"
          fill="url(#paint5_linear_204:475)"
        />
        <path
          d="M113.684 22.875C114.698 22.875 115.52 22.0527 115.52 21.0383C115.52 20.024 114.698 19.2017 113.684 19.2017C112.669 19.2017 111.847 20.024 111.847 21.0383C111.847 22.0527 112.669 22.875 113.684 22.875Z"
          fill="url(#paint6_linear_204:475)"
        />
        <path
          d="M107.158 33.7967C107.425 33.7967 107.641 33.5803 107.641 33.3134C107.641 33.0465 107.425 32.8301 107.158 32.8301C106.891 32.8301 106.674 33.0465 106.674 33.3134C106.674 33.5803 106.891 33.7967 107.158 33.7967Z"
          fill="url(#paint7_linear_204:475)"
        />
        <path
          d="M124.896 129.255C123.464 129.253 122.082 128.725 121.013 127.772C119.944 126.818 119.262 125.506 119.096 124.083L117.163 107.65C117.04 106.631 117.134 105.598 117.44 104.619C117.746 103.64 118.256 102.737 118.937 101.969C119.617 101.202 120.453 100.587 121.389 100.167C122.325 99.7464 123.339 99.5292 124.365 99.5298H125.428C126.458 99.5284 127.477 99.7466 128.417 100.17C129.356 100.593 130.195 101.212 130.876 101.985C131.558 102.758 132.067 103.667 132.369 104.653C132.671 105.638 132.76 106.676 132.63 107.698L130.696 124.132C130.52 125.546 129.833 126.846 128.765 127.79C127.697 128.733 126.321 129.254 124.896 129.255Z"
          fill="url(#paint8_linear_204:475)"
        />
        <path
          d="M36.1106 144.917C36.1106 140.238 37.9693 135.751 41.2777 132.442C44.5862 129.134 49.0734 127.275 53.7523 127.275C58.4312 127.275 62.9184 129.134 66.2269 132.442C69.5354 135.751 71.394 140.238 71.394 144.917"
          fill="url(#paint9_linear_204:475)"
        />
        <path
          d="M0 150C0 142.574 2.94999 135.452 8.20101 130.201C13.452 124.95 20.5739 122 28 122C35.4261 122 42.548 124.95 47.799 130.201C53.05 135.452 56 142.574 56 150"
          fill="url(#paint10_linear_204:475)"
        />
        <path
          d="M53.2385 141.914C53.1117 139.465 53.4847 137.015 54.3347 134.715C55.1847 132.414 56.4939 130.311 58.1827 128.532C59.8715 126.754 61.9045 125.337 64.1581 124.37C66.4116 123.402 68.8385 122.903 71.2911 122.903C73.7436 122.903 76.1705 123.402 78.4241 124.37C80.6776 125.337 82.7106 126.754 84.3994 128.532C86.0882 130.311 87.3975 132.414 88.2475 134.715C89.0974 137.015 89.4704 139.465 89.3436 141.914"
          fill="url(#paint11_linear_204:475)"
        />
        <path
          d="M81.8339 144.916C81.7156 142.789 82.0324 140.661 82.7647 138.661C83.497 136.662 84.6296 134.832 86.0932 133.285C87.5567 131.738 89.3205 130.505 91.2766 129.663C93.2327 128.821 95.3401 128.386 97.4698 128.386C99.5995 128.386 101.707 128.821 103.663 129.663C105.619 130.505 107.383 131.738 108.846 133.285C110.31 134.832 111.443 136.662 112.175 138.661C112.907 140.661 113.224 142.789 113.106 144.916"
          fill="url(#paint12_linear_204:475)"
        />
        <path
          d="M204.891 146.917C204.891 142.238 203.032 137.751 199.724 134.442C196.416 131.134 191.928 129.275 187.249 129.275C182.571 129.275 178.083 131.134 174.775 134.442C171.466 137.751 169.608 142.238 169.608 146.917"
          fill="url(#paint13_linear_204:475)"
        />
        <path
          d="M253 150C253 142.044 249.839 134.413 244.213 128.787C238.587 123.161 230.957 120 223 120C215.044 120 207.413 123.161 201.787 128.787C196.161 134.413 193 142.044 193 150"
          fill="url(#paint14_linear_204:475)"
        />
        <path
          d="M189.762 144.914C189.889 142.465 189.516 140.015 188.666 137.715C187.816 135.414 186.506 133.311 184.818 131.532C183.129 129.754 181.096 128.337 178.842 127.37C176.589 126.402 174.162 125.903 171.709 125.903C169.257 125.903 166.83 126.402 164.576 127.37C162.323 128.337 160.29 129.754 158.601 131.532C156.912 133.311 155.603 135.414 154.753 137.715C153.903 140.015 153.53 142.465 153.657 144.914"
          fill="url(#paint15_linear_204:475)"
        />
        <path
          d="M159.167 142.916C159.285 140.789 158.968 138.661 158.236 136.661C157.503 134.662 156.371 132.832 154.907 131.285C153.444 129.738 151.68 128.505 149.724 127.663C147.768 126.821 145.66 126.386 143.531 126.386C141.401 126.386 139.294 126.821 137.337 127.663C135.381 128.505 133.618 129.738 132.154 131.285C130.69 132.832 129.558 134.662 128.826 136.661C128.093 138.661 127.777 140.789 127.895 142.916"
          fill="url(#paint16_linear_204:475)"
        />
        <path
          d="M187.779 108.424C190.662 108.424 192.999 106.087 192.999 103.204C192.999 100.321 190.662 97.9839 187.779 97.9839C184.896 97.9839 182.559 100.321 182.559 103.204C182.559 106.087 184.896 108.424 187.779 108.424Z"
          fill="url(#paint17_linear_204:475)"
        />
        <path
          d="M175.89 118.865C177.332 118.865 178.5 117.696 178.5 116.255C178.5 114.814 177.332 113.645 175.89 113.645C174.449 113.645 173.28 114.814 173.28 116.255C173.28 117.696 174.449 118.865 175.89 118.865Z"
          fill="url(#paint18_linear_204:475)"
        />
        <path
          d="M194.692 117.028C195.626 117.028 196.383 116.271 196.383 115.337C196.383 114.402 195.626 113.645 194.692 113.645C193.758 113.645 193 114.402 193 115.337C193 116.271 193.758 117.028 194.692 117.028Z"
          fill="url(#paint19_linear_204:475)"
        />
        <path
          d="M172.459 99.7241C172.993 99.7241 173.426 99.2913 173.426 98.7574C173.426 98.2236 172.993 97.7908 172.459 97.7908C171.925 97.7908 171.493 98.2236 171.493 98.7574C171.493 99.2913 171.925 99.7241 172.459 99.7241Z"
          fill="url(#paint20_linear_204:475)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_204:475"
            x1="124.921"
            y1="120.749"
            x2="124.921"
            y2="144.915"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_204:475"
            x1="728.003"
            y1="1075.24"
            x2="728.003"
            y2="137.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273A9B" />
            <stop offset="0.56" stopColor="#202F65" />
            <stop offset="1" stopColor="#021E2F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_204:475"
            x1="12693.2"
            y1="1075.24"
            x2="12693.2"
            y2="137.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273A9B" />
            <stop offset="0.56" stopColor="#202F65" />
            <stop offset="1" stopColor="#021E2F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_204:475"
            x1="155"
            y1="124"
            x2="107.5"
            y2="5.49999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1E0B40" />
            <stop offset="0.52" stopColor="#523C8E" />
            <stop offset="1" stopColor="#FB0691" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_204:475"
            x1="148"
            y1="167.5"
            x2="125"
            y2="39.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#27E9DE" />
            <stop offset="0.52" stopColor="#03A4EC" />
            <stop offset="1" stopColor="#2547A8" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_204:475"
            x1="195.139"
            y1="75.3919"
            x2="352.853"
            y2="86.8759"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#27E9DE" />
            <stop offset="0.52" stopColor="#03A4EC" />
            <stop offset="1" stopColor="#2547A8" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_204:475"
            x1="162.833"
            y1="44.1804"
            x2="253.638"
            y2="50.7924"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#27E9DE" />
            <stop offset="0.52" stopColor="#03A4EC" />
            <stop offset="1" stopColor="#2547A8" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_204:475"
            x1="119.908"
            y1="41.9458"
            x2="143.804"
            y2="43.6858"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#27E9DE" />
            <stop offset="0.52" stopColor="#03A4EC" />
            <stop offset="1" stopColor="#2547A8" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_204:475"
            x1="117.111"
            y1="1278.72"
            x2="173.742"
            y2="67.5628"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273A9B" />
            <stop offset="0.56" stopColor="#202F65" />
            <stop offset="1" stopColor="#021E2F" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_204:475"
            x1="53.7523"
            y1="127.275"
            x2="53.7523"
            y2="144.917"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_204:475"
            x1="28"
            y1="122"
            x2="28"
            y2="150"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_204:475"
            x1="71.2911"
            y1="122.903"
            x2="71.2911"
            y2="141.914"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_204:475"
            x1="97.4698"
            y1="128.386"
            x2="97.4698"
            y2="144.916"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_204:475"
            x1="187.249"
            y1="129.275"
            x2="187.249"
            y2="146.917"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_204:475"
            x1="223"
            y1="120"
            x2="223"
            y2="150"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_204:475"
            x1="171.709"
            y1="125.903"
            x2="171.709"
            y2="144.914"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_204:475"
            x1="143.531"
            y1="126.386"
            x2="143.531"
            y2="142.916"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#90A1BC" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_204:475"
            x1="-3595.83"
            y1="395.107"
            x2="-3595.83"
            y2="265.546"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.52" stopColor="#CCE2E6" />
            <stop offset="1" stopColor="#8FA1BB" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_204:475"
            x1="-242.5"
            y1="265.6"
            x2="-242.5"
            y2="233.236"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.52" stopColor="#CCE2E6" />
            <stop offset="1" stopColor="#8FA1BB" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_204:475"
            x1="936.587"
            y1="209.089"
            x2="936.587"
            y2="195.386"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.52" stopColor="#CCE2E6" />
            <stop offset="1" stopColor="#8FA1BB" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_204:475"
            x1="1879.6"
            y1="144.558"
            x2="1879.6"
            y2="140.189"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.52" stopColor="#CCE2E6" />
            <stop offset="1" stopColor="#8FA1BB" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
