export const ArrowLink = ({ children, href }) => {
  return (
    <a href={href} className="arrow-link" target="_blank" rel="noreferrer">
      <svg
        width="26"
        height="26"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 24.5H41"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M29.5 13L41 24.5L29.5 36"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
      {children}
    </a>
  );
};
