import { Button } from "@mui/material";
import { useState } from "react";

export const WebinarItem = ({ webinarData, handleOpenModal, text }) => {
  const { status, starts_at } = webinarData;
  const dateTime = new Date(starts_at);
  const isActive = status === "opened";


   const date = text.language === "de" ?
       dateTime.toLocaleString("de-DE", {
           day: "2-digit",
           month: "2-digit",
           year: "numeric",
       }) :
       dateTime.toLocaleString("en-US", {
           day: "2-digit",
           month: "2-digit",
           year: "numeric",
       })


  const time = text.language === "de" ?
      `${dateTime.toLocaleString("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
      })} Uhr` :
      `${dateTime.toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
      })} `


    const weekDay = text.language === "de" ?
        dateTime.toLocaleString("de-DE", { weekday: "long" }) :
        dateTime.toLocaleString("en-US", { weekday: "long" })

  return (
    <div className={`webinar-item webinar-item--${status}`}>
      <div className="webinar-item__status"></div>
      <div className="webinar-item__column">
        <div className="webinar-item__label">{weekDay}</div>
        <div className="webinar-item__value">{date}</div>
      </div>
      <div className="webinar-item__column">
        <div className="webinar-item__label">{text.time}</div>
        <div className="webinar-item__value">{time}</div>
      </div>
      <div className="webinar-item__button-wrapper">
        <Button
          className="webinar-item__button"
          disabled={!isActive}
          onClick={() => handleOpenModal(webinarData)}
        >
          {isActive ? `${text.join}` : `${text.completed}`}
        </Button>
      </div>
    </div>
  );
};
