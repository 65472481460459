import { Logo } from "./components/Logo";
import { RocketLogo } from "./components/RocketLogo";
import { ResultPreview } from "./components/ResultPreview";
import { Connector } from "./components/Connector";
import { CopyButton } from "./components/CopyButton";
import { WebinarModal } from "./components/WebinarModal";
import { ArrowLink } from "./components/ArrowLink";
import { WebinarItem } from "./components/WebinarItem";
import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { AppBar, Container, Button } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getLandingData, generateText } from "./utils/apiCalls";
import {language} from "./language/language"


const randomArrayItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

const param = new URLSearchParams(window.location.search);
const lang = param.get("language");
let text = language[lang] || language.de
const defaultAbout = text.language === "de" ?
	[
		"VW Golf",
		"Bosch Akkuschrauber",
		"Miele Geschirrspüler",
	]:
	[
		"VW Golf",
		"Bosch cordless screwdriver",
		"Miele dishwasher",
	];



export const App = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [generatedResult, setGeneratedResult] = useState(null);
	const [aboutInput, setAboutInput] = useState(randomArrayItem(defaultAbout));
	const [presets, setPresets] = useState([]);
	const [selectedPreset, setSelectedPreset] = useState("");
	const [webinars, setWebinars] = useState([]);
	const [selectedWebinar, setSelectedWebinar] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [error, setError] = useState("");
	const currentYear = new Date().getFullYear();
	const webinarSection = useRef();
	const window = useRef();
	const result = useRef();


	const onCopyClick = () => {
		navigator.clipboard.writeText(generatedResult.text).then(() => {});
	};

	useEffect(() => {
		let isEn = text.language === 'en';
		getLandingData(isEn).then(({ data }) => {
			const { presets, webinars } = data;
			setSelectedPreset(presets[0].id || "");
			setPresets(presets);
			setWebinars(webinars);
		});
	}, []);

	const handleWebinarNavClick = () => {
		webinarSection.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	};

	const handleLogoClick = () => {
		window.current.scrollIntoView({ behavior: "smooth" });
	};

	const handleOpenModal = useCallback((webinarData) => {
		setSelectedWebinar(webinarData);
		setIsModalOpen(true);
	}, []);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleGenerateClick = async () => {
		result.current.scrollIntoView({ behavior: "smooth", block: "center" });
		setError("");
		setIsLoading(true);
		const reqData = {
			preset_id: selectedPreset,
			about: aboutInput,
		};
		await generateText(reqData)
			.then(({ data }) => {
				setGeneratedResult(data);
			})
			.catch(({ response }) => {
				if (response?.status === 429) {
					text.language === "de" ?
						setError(
							"Das Anfragelimit wurde erreicht. Bitte versuchen Sie es in 3 Minuten erneut.",
						) :
						setError(
							"The request limit has been reached. Please try again in 3 minutes.",
						);
				} else {
					text.language === "de" ?
						setError(
							"Leider ist etwas schief gelaufen. Bitte probieren Sie es erneut.",
						) :
						setError(
							"Unfortunately, something went wrong. Please try it again.",
						);
				}
			});
		setIsLoading(false);
	};

	const formatResultDate = (date) => {
		const newDate = new Date(date);
		return text.language === "de" ?
			`${newDate.toLocaleString("de-DE", {
				day: "2-digit",
				month: "long",
				year: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})} Uhr` :
			`${newDate.toLocaleString("en-US", {
				day: "2-digit",
				month: "long",
				year: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})}`
	};

	return (
		<StyledEngineProvider injectFirst>
			<div className="App" ref={window}>
				<AppBar className="header">
					<Container className="container header__container">
						<Button onClick={handleLogoClick}>
							<Logo />
						</Button>
						<Button className="header__button" onClick={handleWebinarNavClick}>
							{text.webinarTerm}
						</Button>
					</Container>
				</AppBar>
				<main>
					<Container className="container">
						<section className="hero">
							<h1 className="hero__title">{text.langGeneration}</h1>
							<p className="hero__text">
								{text.NlgIs}
							</p>
							<div className="actions">
								<label className="actions__select-label">{text.need}</label>
								<div className="actions__select-wrapper">
									<Select
										displayEmpty
										defaultValue={presets[1]?.id}
										className="actions__select"
										value={selectedPreset}
										onChange={(e) => setSelectedPreset(e.target.value)}
									>
										{presets.map(({ id, name }) => (
											<MenuItem key={id} value={id}>
												{name}
											</MenuItem>
										))}
									</Select>
								</div>
								<label className="actions__input-label">{text.about}</label>
								<div className="actions__input-wrapper">
									<OutlinedInput
										className="actions__input"
										value={aboutInput}
										onChange={(e) => setAboutInput(e.target.value)}
									/>
								</div>
								<div className="actions__button-wrapper">
									<Button
										className="actions__generate-button"
										variant="contained"
										onClick={handleGenerateClick}
										disabled={!aboutInput || !selectedPreset}
									>
										{text.generateText}
									</Button>
								</div>
							</div>
							<Connector />
						</section>
						<section className="result-section" ref={result}>
							{generatedResult && !isLoading && !error ? (
								<div className="result-generated">
									<div className="result-generated__heading">
										<span>{`${text.your} "${generatedResult.presetName}"`}</span>
										<span>{`${text.onTheSubject} "${generatedResult.about}"`}</span>
									</div>
									<div className="result-generated__card">
										<div className="result-generated__text">{generatedResult.text}</div>
										<div className="result-generated__footer">
											<span className="result-generated__date">
												{formatResultDate(generatedResult.date)}
											</span>
											<CopyButton onClick={onCopyClick} />
										</div>
									</div>
								</div>
							) : (
								<ResultPreview isLoading={isLoading} error={error} text={text}/>
							)}
						</section>

						<section className="webinars" ref={webinarSection}>
							<div className="webinars__heading">
								<div className="webinars__logo">
									<RocketLogo />
								</div>
								<h2 className="webinars__title">{text.readyTakeoff}</h2>
								<p className="webinars__text">
									{text.joinFree}
								</p>
							</div>
							<div className="webinars__list">
								{webinars.map((webinar) => (
									<WebinarItem
										webinarData={webinar}
										key={webinar.id}
										handleOpenModal={handleOpenModal}
										text={text}
									/>
								))}
							</div>
							<h2 className="webinars__title">{text.questions}</h2>
							<p className="webinars__text">
								{text.contact}{" "}
								<a href="mailto:info@next-nlg.com" className="webinars__link">
									info@next-nlg.com
								</a>
								<br /> {text.underContact}
							</p>
						</section>
					</Container>
				</main>
				<footer className="footer">
					<Container className="container">
						<div className="footer__inner">
							<Logo isWhite />
							<div className="footer__info">
								<p className="footer__copyrights">
									© next-nlg.com {currentYear}, {text.allRights}
								</p>
								<ArrowLink href={"https://www.kittelberger.de/impressum"}>{text.imprint}</ArrowLink>
								<ArrowLink href={"https://www.kittelberger.de/datenschutz"}>
									{text.legal}
								</ArrowLink>
							</div>
						</div>
					</Container>
				</footer>
				<WebinarModal
					open={isModalOpen}
					onClose={handleCloseModal}
					selectedWebinar={selectedWebinar}
					text={text}
				/>
			</div>
		</StyledEngineProvider>
	);
};
