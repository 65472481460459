import axios from "axios";

const request = axios.create({
  baseURL: `https://demo.next-nlg.com/api`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getLandingData = (isEn) => {
  return request({
    method: "GET",
    url: isEn ? `/landing?language=en` : `/landing?language=de`,
  });
};

export const generateText = (data) => {
  return request({
    method: "POST",
    url: `/landing/generate`,
    data: JSON.stringify(data),
  });
};
